import React from "react"
import { Helmet } from "react-helmet"
import TemplatePage from "../../components/TemplatePage"
import { colors } from "../../styles/globals"

export default function Pcs({ location }) {
  return (
    <TemplatePage
      location={location}
      title="Registru de evidență"
      additionalClassName="templateFullContainerFaqScreen"
    >
      {/* <TabelLinkuri items={items} />
       */}
      <Helmet>
        <title>Registru Evidenta - Gazmir</title>
        <meta name="description" content="Vezi Registrul de Evidenta Gazmir" />
      </Helmet>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: 32,
        }}
      >
        <iframe
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="yes"
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT8F5kfzHz2_O2YQTkP6GsnIcD9MDna94wHcF2Knk4XI7rgtDTjFNPJzCCF9SDlV0agLlFJpuJQxmdo/pubhtml"
          allowFullScreen=""
        ></iframe>
        <a
          target="_blank"
          // className=""
          style={{
            color: colors.main,
            // textDecoration: "none",
            alignSelf: "flex-start",
          }}
          href="https://docs.google.com/spreadsheets/d/e/2PACX-1vT8F5kfzHz2_O2YQTkP6GsnIcD9MDna94wHcF2Knk4XI7rgtDTjFNPJzCCF9SDlV0agLlFJpuJQxmdo/pubhtml"
        >
          Pentru a vizualiza Registru de evidență Gazmir 2023 apăsați aici
        </a>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: 32,
        }}
      >
        <iframe
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="yes"
          src="https://docs.google.com/spreadsheets/d/1gtY2nd1N13qBfgj05rUFgEThUZQYVkf9vOH3GHN23KE/preview?usp=drivesdk"
          allowFullScreen=""
        ></iframe>
        <a
          target="_blank"
          // className=""
          style={{
            color: colors.main,
            // textDecoration: "none",
            alignSelf: "flex-start",
          }}
          href="https://docs.google.com/spreadsheets/d/1gtY2nd1N13qBfgj05rUFgEThUZQYVkf9vOH3GHN23KE/preview?usp=drivesdk"
        >
          Pentru a vizualiza Registru de evidență Gazmir 2021-2022 apăsați aici
        </a>
      </div>
    </TemplatePage>
  )
}
